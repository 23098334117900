import { useState, useEffect, memo } from 'react';
import EntityV2 from '../components/entityv2';
import picListener from '../components/utils/picListener';

import noTask from "../assets/no-task.png";
import bg01_layer_ss from '../assets/bg01_layer_ss.jpg'
import bg02_layer_ss from '../assets/bg02_layer_ss.jpg'
import bg03_layer_ss from '../assets/bg03_layer_ss.jpg'

import taskStatusApi from '../api/taskinfo';
import freames from './freams/task';

let loadedCount = 0;

const pics = {
    task1: { // 外景1
        src: bg02_layer_ss, width: 200, height: 279, row: 10, col: 10,
        style: { top: 80, left: 0, position: 'absolute' },
        animates: { auto: freames.slice(0, 99) },
        autoPlay: true,
        play: 'auto',
        id: 'task1',
        task_id: 6,
    },
    task2: { // 外景2
        src: bg03_layer_ss, width: 200, height: 279, row: 10, col: 10,
        style: { top: 80, left: 0, position: 'absolute' },
        animates: { auto: freames.slice(0, 99) },
        autoPlay: true,
        play: 'auto',
        id: 'task2',
        task_id: 7,
    },
    task3: { // 外景3
        src: bg01_layer_ss, width: 200, height: 279, row: 10, col: 10,
        style: { top: 80, left: 0, position: 'absolute' },
        animates: { auto: freames.slice(0, 99) },
        autoPlay: true,
        play: 'auto',
        id: 'task3',
        task_id: 8,
    },
};

const TaskEntity = () => {
    const [task, setTask] = useState(0);
    const [loop, setLoop] = useState(true);
    const [onlyFirstFrame, setOnlyFirstFrame] = useState(false);

    const getTaskStatus = async () => {
        const data = await taskStatusApi();
        if (data && data.over_time) {
            setTask(data.task_id)
            setOnlyFirstFrame(false);
            setTimeout(() => {
                setOnlyFirstFrame(true)
            }, 1000 * 60)
        }
    }

    const onLoad = () => {
        loadedCount++;
        if (loadedCount >= 4) {
            picListener.down('task');
        }
    }

    useEffect(() => {
        getTaskStatus();
        picListener.on('dispatch_task', 'task', () => {
            setTimeout(() => {
                getTaskStatus();
            }, 3000)
        })
        setTimeout(() => {
            setOnlyFirstFrame(true)
        }, 1000 * 60)
    }, [])
    return <>
        <img src={noTask} onLoad={onLoad} style={{
            ...(pics.task1?.style || {}), opacity: task === 0 ? 1 : 0,
            width: 300,
        }} />
        {EntityV2({
            layoutStyle: pics.task1?.style || {},
            width: pics.task1?.width,
            height: pics.task1?.height,
            animates: pics.task1?.animates,
            autoPlay: pics.task1?.autoPlay,
            show: task === pics.task1.task_id,
            loop,
            row: pics.task1?.row,
            col: pics.task1?.col,
            play: pics.task1?.play,
            status: 1,
            start: false,
            id: pics.task1?.id,
            key: pics.task1?.id,
            url: pics.task1?.src,
            onlyFirstFrame,
            onLoad: onLoad,
        })}
        {EntityV2({
            layoutStyle: pics.task2?.style || {},
            width: pics.task2?.width,
            height: pics.task2?.height,
            animates: pics.task2?.animates,
            autoPlay: pics.task2?.autoPlay,
            show: task === pics.task2.task_id,
            loop,
            row: pics.task2?.row,
            col: pics.task2?.col,
            play: pics.task2?.play,
            status: 1,
            start: false,
            id: pics.task2?.id,
            key: pics.task2?.id,
            url: pics.task2?.src,
            onlyFirstFrame,
            onLoad: onLoad,
        })}
        {EntityV2({
            layoutStyle: pics.task3?.style || {},
            width: pics.task3?.width,
            height: pics.task3?.height,
            animates: pics.task3?.animates,
            autoPlay: pics.task3?.autoPlay,
            show: task === pics.task3.task_id,
            loop,
            row: pics.task3?.row,
            col: pics.task3?.col,
            play: pics.task3?.play,
            status: 1,
            start: false,
            id: pics.task3?.id,
            key: pics.task3?.id,
            url: pics.task3?.src,
            onlyFirstFrame,
            onLoad: onLoad,
        })}
    </>
}

export default memo(TaskEntity)