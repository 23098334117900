import { Toast } from 'antd-mobile';
import request from '../request';

export default async function () {
    try {
        const result = await request.post('/api/task/create', {}, {});
        if (result.status === 200 && result.data) {
            if (result.data.code === 200) {
                return result.data.data
            } else {
                Toast.show({
                    icon: 'fail',
                    content: result.data.msg || '网络错误,请稍后再试.',
                })
            }
        } else {
            Toast.show({
                icon: 'fail',
                content: result.error || '网络错误,请稍后再试.',
            })
        }
    } catch (e) {
        Toast.show({
            icon: 'fail',
            content: e.message || '网络错误,请稍后再试.',
        })
    }
}