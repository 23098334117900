import { useState, useEffect, useCallback, memo, useRef } from 'react';
import s from './style.module.css';
const ImageCache = ({ src, alt, style, onLoad }) => {
    const [imageSrc, setImageSrc] = useState(null);
    useEffect(() => {
        // 预加载图片并存储在缓存中
        const img = new Image();
        img.onload = () => setImageSrc(src);
        img.src = src;
    }, [src]);
    return <img src={imageSrc || src} alt={alt} style={style} onLoad={onLoad} />;
};

const EntityFnc = (props) => {
    let frame = 0;

    const Entity = ({
        id,
        show,
        onlyFirstFrame,
        url,
        width,
        height,
        onLoad,
        autoStart,
        play,
        animates,
        row,
        col,
        blank,
        layoutStyle,
        loop = false,
        playEnd,
        hangup,
        onClick,
    }) => {
        const ref = useRef(null);
        const [times, setTimes] = useState([]);

        const nextFrame = (ref, animateData) => {
            const [newLeft, newTop] = animateData[frame];
            if (ref.current) {
                ref.current.style.transform = `translate(${newLeft}px,${newTop}px)`;
            }
        }

        const animate = (animateData, isLoop, frameCount) => {
            if (show) {
                if (frameCount % 5 === 0) {
                    if (!animateData[frame]) {
                        if (hangup && (!isLoop || onlyFirstFrame)) {
                            times.forEach(t => {
                                cancelAnimationFrame(t)
                            })
                            setTimes([])
                            if (playEnd) playEnd();
                            frame = 0;
                            return;
                        }
                        frame = 0;
                        nextFrame(ref, animateData);

                        if (!isLoop || onlyFirstFrame) {
                            times.forEach(t => {
                                cancelAnimationFrame(t)
                            })
                            setTimes([])
                            if (playEnd) playEnd();
                            return;
                        }
                    } else {
                        nextFrame(ref, animateData);
                        frame++;
                    }
                }
                times.push(requestAnimationFrame(animate.bind(this, animateData, isLoop, frameCount + 1)))
            }
        }

        useEffect(() => {
            if (autoStart && play && animates && animates[play] && !onlyFirstFrame) {
                console.log(23232323)
                times.push(requestAnimationFrame(animate.bind(this, animates[play], loop, 0)));
            }
        }, [])

        useEffect(() => {
            if (animates && animates[play] && times && show && !onlyFirstFrame) {
                if (times.length) {
                    times.forEach(t => cancelAnimationFrame(t))
                    setTimes([]);
                }
                frame = 0;
                times.push(requestAnimationFrame(animate.bind(this, animates[play], loop, 0)));
            }
        }, [play, show])

        return (
            <div style={{
                width,
                height,
                overflow: 'hidden',
                position: 'relative',
                ...layoutStyle,
                opacity: ((show || hangup) || (show && onlyFirstFrame)) ? 1 : 0,
            }}
                id={`entity_${id}`}
            >
                <img
                    onContextMenu={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onTouchStart={e => {
                        if (typeof onClick === 'function') onClick();
                        e.stopPropagation();
                    }}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    style={{
                        width: width * col,
                        height: height * row,
                        position: 'absolute',
                        transform: 'translate(0, 0)',
                    }}
                    ref={ref}
                    src={url}
                    onLoad={onLoad}
                />
            </div>
        )
    }
    return <Entity {...props} key={props.key} />;
}

export default EntityFnc