import { memo, useEffect, useState } from 'react';
import EntityV2 from '../entityv2';
import s from './style.module.css'

import picListener from '../utils/picListener';
import loadingPic from '../../assets/ui/loading.jpg';
import loadingGif from '../../assets/ui/load.gif';

const Loading = () => {
    const [loaded, setLoaded] = useState(false);
    const [close, setClose] = useState(false);

    useEffect(() => {
        const dom = document.getElementById('loading')
        setTimeout(() => {
            dom.style.display = 'none';
        }, 1000)
        picListener.listen('loading', () => {
            setTimeout(() => {
                setClose(true)
                setTimeout(() => {
                    setLoaded(true);
                }, 1000)
            }, 5000)
        })
    }, [])

    if (loaded) return null;

    return (
        <div className={`${s.loading} ${close ? s.close : ''}`}>
            <img src={loadingPic} className={s.loadingJpg} />
            <img src={loadingGif} className={s.loadingGif} />
        </div>
    )
}

export default memo(Loading)