import './fixWidth';
import { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Entity from './components/entity'
import UI from './components/ui';
import Scene from './components/scene/index';
import Loading from './entitys/loading';
import Popup from './components/popup'
import CharacterEntity from './entitys/character';
// import ScrrenEntity from './entitys/screen';
import TaskEntity from './entitys/task';
import { completionPaddingByIos } from './utils';

function App() {
  return (
    <div className="App">
      <div className="App-header" style={completionPaddingByIos()}>
        {/* <Loading /> */}
        <Popup />
        <Scene>
          <CharacterEntity />
          {/* <ScrrenEntity /> */}
          <TaskEntity />
        </Scene>
        <UI />
      </div>
    </div>
  );
}

export default App;
