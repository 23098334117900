import { useState, useEffect, useCallback, memo } from 'react';
import s from './style.module.css';

import head from '../../assets/ui/head.png';
import gold from '../../assets/ui/gold.png';
import icon from '../../assets/ui/icon-qd.png';
import panel from '../../assets/ui/panel.png';
import arrow from "../../assets/ui/arrow.png"

import picListener from '../utils/picListener';

const UserInfo = ({ userInfo }) => {

    const jumpApp = () => {
        if (window.uni) {
            window.uni.navigateTo({
                url: '/pages/points/task'
            });
        }
    }

    const jumpPoints = () => {
        if (window.uni) {
            window.uni.navigateTo({
                url: '/pages/points/index'
            });
        }
    }

    return (
        <>
            <div className={s.panelLayout}>
                <div className={s.panelLayoutBg}><img src={panel} className={s.panelImg} /></div>
                <div className={s.panelLayoutContent}>
                    <div className={s.panelLayoutContentTitle}>
                        <img src={userInfo.info.avatar || head} className={s.panelLayoutContentHead} />
                        {userInfo.name}
                    </div>

                    <div className={s.panelLayoutContentGoldInfo}>
                        <div className={s.goldNum}>金豆数量</div>
                        <div className={s.goldInfo}>
                            <img className={s.goldIcon} src={gold} />
                            <div className={s.num}>{userInfo.points}</div>
                        </div>
                    </div>

                    <div className={s.line}></div>
                    <div className={s.record} onClick={jumpPoints}>积分记录</div>
                </div>
                <div className={s.panelBgBtn} onClick={jumpApp}>
                    <img src={icon} className={s.panelBgBtnIcon} />兑福利
                </div>
            </div>
            <div className={s.panelBg}></div>
        </>
    )
}

export default memo(UserInfo);