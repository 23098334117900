import axios from 'axios'

const qs = new URLSearchParams(window.location.search)

function getHandle(host, isHttp) {
    return (path, data, headers) => {
        const params = {
            method: 'get',
            url: `${isHttp ? 'http' : 'https'}://${host}${path}`,
        }
        if (data) {
            params.params = data;
        }
        params.headers = {
            Authorization: `Bearer ${qs.get('token')}`
        }
        if (headers) {
            params.headers = {
                ...params.headers,
                ...headers,
            };
        }
        try {
            const req = axios(params);
            return req;
        } catch (e) {
            throw new Error(e);
        }
    }
}

function postHandle(host, isHttp) {
    return (path, data, headers) => {
        const params = {
            method: 'post',
            url: `${isHttp ? 'http' : 'https'}://${host}${path}`,
        }
        if (data) {
            params.data = data;
        }
        params.headers = {
            Authorization: `Bearer ${qs.get('token')}`
        }
        if (headers) {
            params.headers = {
                ...params.headers,
                ...headers,
            };
        }
        try {
            const req = axios(params);
            return req;
        } catch (e) {
            throw new Error(e);
        }
    }
}

function r(host, useHttp) {
    return {
        get: getHandle(host, useHttp),
        post: postHandle(host, useHttp),
    }
}

export default r('api.lanjlai.com', false);