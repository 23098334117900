export default [
    // 第一行
    [0, 0],
    [-200, 0],
    [-400, 0],
    [-600, 0],
    [-800, 0],
    [-1000, 0],
    [-1200, 0],
    [-1400, 0],
    [-1600, 0],
    [-1800, 0],

    [0, -279],
    [-200, -279],
    [-400, -279],
    [-600, -279],
    [-800, -279],
    [-1000, -279],
    [-1200, -279],
    [-1400, -279],
    [-1600, -279],
    [-1800, -279],

    [0, -558],
    [-200, -558],
    [-400, -558],
    [-600, -558],
    [-800, -558],
    [-1000, -558],
    [-1200, -558],
    [-1400, -558],
    [-1600, -558],
    [-1800, -558],

    [0, -837],
    [-200, -837],
    [-400, -837],
    [-600, -837],
    [-800, -837],
    [-1000, -837],
    [-1200, -837],
    [-1400, -837],
    [-1600, -837],
    [-1800, -837],

    [0, -1116],
    [-200, -1116],
    [-400, -1116],
    [-600, -1116],
    [-800, -1116],
    [-1000, -1116],
    [-1200, -1116],
    [-1400, -1116],
    [-1600, -1116],
    [-1800, -1116],

    [0, -1395],
    [-200, -1395],
    [-400, -1395],
    [-600, -1395],
    [-800, -1395],
    [-1000, -1395],
    [-1200, -1395],
    [-1400, -1395],
    [-1600, -1395],
    [-1800, -1395],

    [0, -1674],
    [-200, -1674],
    [-400, -1674],
    [-600, -1674],
    [-800, -1674],
    [-1000, -1674],
    [-1200, -1674],
    [-1400, -1674],
    [-1600, -1674],
    [-1800, -1674],

    [0, -1953],
    [-200, -1953],
    [-400, -1953],
    [-600, -1953],
    [-800, -1953],
    [-1000, -1953],
    [-1200, -1953],
    [-1400, -1953],
    [-1600, -1953],
    [-1800, -1953],

    [0, -2232],
    [-200, -2232],
    [-400, -2232],
    [-600, -2232],
    [-800, -2232],
    [-1000, -2232],
    [-1200, -2232],
    [-1400, -2232],
    [-1600, -2232],
    [-1800, -2232],

    [0, -2511],
    [-200, -2511],
    [-400, -2511],
    [-600, -2511],
    [-800, -2511],
    [-1000, -2511],
    [-1200, -2511],
    [-1400, -2511],
    [-1600, -2511],
    [-1800, -2511],
]
