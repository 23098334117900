import { useState, useEffect, memo, useCallback } from "react";
import { Popup } from 'antd-mobile'
import s from './style.module.css';
import { completionPaddingByIos } from '../../utils';

const Code = ({ visible, onClose, code }) => {
    return (
        <Popup
            visible={visible}
            bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                ...completionPaddingByIos(),
            }}
            onMaskClick={() => {
                onClose()
            }}
        >
            <div>
                <div className={s.layout}>
                    <div className={s.title}>邀请码</div>
                    <div className={s.code}>
                        {code}
                    </div>
                </div>
                <div className={s.bottomBtnPanel}>
                    <div onClick={onClose} className={s.bottomBtn}>确定</div>
                </div>
            </div>
        </Popup>
    )
}

export default memo(Code);