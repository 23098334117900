export default [
    // 第一行
    [0, 0],
    [-200, 0],
    [-400, 0],
    [-600, 0],
    [-800, 0],
    [-1000, 0],
    [-1200, 0],
    [-1400, 0],
    [-1600, 0],
    [-1800, 0],

    [0, -350],
    [-200, -350],
    [-400, -350],
    [-600, -350],
    [-800, -350],
    [-1000, -350],
    [-1200, -350],
    [-1400, -350],
    [-1600, -350],
    [-1800, -350],

    [0, -700],
    [-200, -700],
    [-400, -700],
    [-600, -700],
    [-800, -700],
    [-1000, -700],
    [-1200, -700],
    [-1400, -700],
    [-1600, -700],
    [-1800, -700],

    [0, -1050],
    [-200, -1050],
    [-400, -1050],
    [-600, -1050],
    [-800, -1050],
    [-1000, -1050],
    [-1200, -1050],
    [-1400, -1050],
    [-1600, -1050],
    [-1800, -1050],

    [0, -1400],
    [-200, -1400],
    [-400, -1400],
    [-600, -1400],
    [-800, -1400],
    [-1000, -1400],
    [-1200, -1400],
    [-1400, -1400],
    [-1600, -1400],
    [-1800, -1400],

    [0, -1750],
    [-200, -1750],
    [-400, -1750],
    [-600, -1750],
    [-800, -1750],
    [-1000, -1750],
    [-1200, -1750],
    [-1400, -1750],
    [-1600, -1750],
    [-1800, -1750],

    [0, -2100],
    [-200, -2100],
    [-400, -2100],
    [-600, -2100],
    [-800, -2100],
    [-1000, -2100],
    [-1200, -2100],
    [-1400, -2100],
    [-1600, -2100],
    [-1800, -2100],

    [0, -2450],
    [-200, -2450],
    [-400, -2450],
    [-600, -2450],
    [-800, -2450],
    [-1000, -2450],
    [-1200, -2450],
    [-1400, -2450],
    [-1600, -2450],
    [-1800, -2450],

    [0, -2800],
    [-200, -2800],
    [-400, -2800],
    [-600, -2800],
    [-800, -2800],
    [-1000, -2800],
    [-1200, -2800],
    [-1400, -2800],
    [-1600, -2800],
    [-1800, -2800],

    [0, -3150],
    [-200, -3150],
    [-400, -3150],
    [-600, -3150],
    [-800, -3150],
    [-1000, -3150],
    [-1200, -3150],
    [-1400, -3150],
    [-1600, -3150],
    [-1800, -3150],
]
