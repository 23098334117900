import { useState, useEffect, memo } from 'react';
import { Popup } from 'antd-mobile'
import s from './style.module.css';
import './rewrite.css'

import taskbg3 from '../../assets/ui/taskbg1.png';
import taskbg1 from '../../assets/ui/taskbg2.png';
import taskbg2 from '../../assets/ui/taskbg3.png';
import gold from '../../assets/ui/gold.png';
import { completionPaddingByIos } from '../../utils';

const bgs = {
    "6": taskbg1,
    "7": taskbg2,
    "8": taskbg3
}

const OutTask = ({ visible, onClose, onOk, tasks }) => {
    const accept = (id) => {
        if (onOk) {
            onOk(id)
        }
        onClose()
    }
    return (
        <Popup
            visible={visible}
            bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                ...completionPaddingByIos(),
            }}
            onMaskClick={() => {
                onClose()
            }}
        >
            <div className={s.title}>
                外出任务
            </div>
            <div className={s.taskList}>
                {(tasks || []).map(item => (
                    <div key={item.id} className={`${s.taskItem} ${s.task1}`}>
                        <img src={bgs[item.id]} className={s.bg} />
                        <div className={s.infoPanel}>
                            <div className={s.taskName}>{item.name}</div>
                            <div className={s.taskInfo}>
                                <div className={s.time}>时长:</div>
                                <div>{item.time}小时</div>
                            </div>
                            <div className={s.gift}>
                                <div>奖励</div>
                                <div>{item.return_points}个<img className={s.icon} src={gold} /></div>
                            </div>
                            <div onClick={() => accept(item.id)} className={s.btn}>立即派遣</div>
                        </div>
                    </div>
                ))}
            </div>
        </Popup>
    )
}

export default memo(OutTask);