import { memo, useEffect, useState } from "react";
import { Popover } from 'antd-mobile';
import s from './style.module.css'
import taskStatusApi from '../../api/taskinfo';
import picListener from "../utils/picListener";

let textTimer;
const Popup = () => {
    const [helloText, setHelloText] = useState('');
    const [visible, setVisible] = useState(true);
    const startTextTick = () => {
        if (!visible) return;
        if (!textTimer) {
            textTimer = setInterval(() => {
                setHelloText('欢迎来到金铃元宇宙！');
                setTimeout(() => {
                    setHelloText('');
                }, 3000)
            }, 20000);
        }
    }

    const taskStatus = async () => {
        const data = await taskStatusApi();
        if (data && data.over_time) {
            setVisible(false)
        }
    }

    useEffect(() => {
        startTextTick();
        taskStatus();

        picListener.on('dispatch_task', 'popup', () => {
            setVisible(false);
        })
    }, [])

    return (
        <Popover style={{ zIndex: 500 }} content={helloText} position="top" visible={helloText !== '' && visible}>
            <div className={s.popup}></div>
        </Popover>
    )
}

export default memo(Popup)