import { useState, useEffect, useCallback, memo } from 'react';
import s from './style.module.css';

import gold from '../../assets/ui/gold.png';
import Checkmark from '../../assets/ui/Checkmark.png';

import taskListApi from '../../api/tasklist';

const TaskCenter = () => {
    const [tasks, setTasks] = useState([{
        name: '邀请入驻',
        checked: false,
    }, {
        name: '完成订单',
        checked: false,
    }, {
        name: '个人认证',
        checked: false,
    }, {
        name: '企业认证',
        checked: false,
    }, {
        name: '完成评价',
        checked: false,
    }])

    const getTaskList = async () => {
        const data = await taskListApi(0);
        setTasks((data || []).map(item => ({ name: item.name, checked: item.finish, return_points: item.return_points })));
    }

    useEffect(() => {
        getTaskList()
    }, [])

    return (
        <div className={s.taskLayout}>
            <div className={s.taskTitle}>任务中心</div>
            <div className={s.taskList}>
                {tasks.map((task, index) => (
                    <div key={task.name} className={s.taskItem}>
                        <div className={s.taskInfo}>
                            <div className={s.taskName}>{task.name}</div>
                            <div className={s.taskIcon}>
                                <img src={task.checked ? Checkmark : gold} className={s.icon} />
                                {!task.checked && <i className={s.taskGoldAddNum}>+{task.return_points}</i>}
                            </div>
                        </div>
                        {index < tasks.length - 1 && <div className={s.taskLine}></div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default memo(TaskCenter);