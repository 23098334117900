
const pics = {
    // character: false,
    // task: false,
    // screen: false,
};
const listeners = {};
const events = {};

function down(type) {
    pics[type] = true;
    for (var key in pics) {
        if (!pics[key]) return;
    }
    for (var key in listeners) {
        if (listeners[key]) {
            listeners[key]();
        }
    }
}

function listen(name, cb) {
    if (listeners[name]) return;
    listeners[name] = cb;
}

function on(name, id, cb) {
    if (!events[name]) events[name] = {};
    if (!events[name][id]) {
        events[name][id] = cb;
    }
}

function trigger(name, data) {
    if (!events[name]) return;
    for (let id in events[name]) {
        events[name][id](data);
    }
}

export default {
    down,
    listen,
    on,
    trigger,
}
