import { useEffect, useState } from "react";
import userInfoApi from '../api/userinfo';

let cacheUserInfo = null;

export default () => {
    const [userInfo, setUserInfo] = useState({ info: {} });
    const [error, setError] = useState(false);

    const getUserInfo = async () => {
        if (cacheUserInfo) {
            setUserInfo(cacheUserInfo)
            return;
        }
        const data = await userInfoApi();
        if (data) {
            setUserInfo(data);
            setError(false);
        } else {
            setError(true);
        }
    }

    return {
        data: userInfo,
        request: getUserInfo,
        error,
    }
}