import { useState, useEffect, memo } from 'react';
import EntityV2 from '../components/entityv2';
import useUserInfo from '../hooks/useUserInfo';
import picListener from '../components/utils/picListener';

import charac_suit01_idle_layer_ss from '../assets/charac_suit01_idle_layer_ss.webp'
import charac_suit01_wave_layer_ss from '../assets/charac_suit01_wave_layer_ss.webp'
import charac_suit02_idle_layer_ss from "../assets/charac_suit02_idle_layer_ss.webp"
import charac_suit02_wave_layer_ss from "../assets/charac_suit02_wave_layer_ss.webp"
import charac_suit03_idle_layer_ss from "../assets/charac_suit03_idle_layer_ss.webp"
import charac_suit03_wave_layer_ss from "../assets/charac_suit03_wave_layer_ss.webp"

import taskStatusApi from '../api/taskinfo';
import frames from './freams/character';

let loadedCount = 0;
let helloTimer;
let playTimer;
let playEndTimer = null;

const pics = {
    standby1: { // 待机level1
        src: charac_suit01_idle_layer_ss, width: 200, height: 350, row: 10, col: 10,
        style: { position: 'absolute', top: 190, left: 140, zIndex: 60 },
        animates: { auto: frames.slice(0, 94) },
        autoPlay: true,
        play: 'auto',
        id: 'standby1',
    },
    // hello1: { // 打招呼level1
    //     src: charac_suit01_wave_layer_ss, width: 200, height: 350, row: 6, col: 10,
    //     style: { position: 'absolute', top: 190, left: 140, zIndex: 60 },
    //     animates: { auto: frames.slice(0, 57) },
    //     autoPlay: false,
    //     play: 'auto',
    //     id: 'hello1',
    // },
    standby2: { // 待机level2
        src: charac_suit02_idle_layer_ss, width: 200, height: 350, row: 10, col: 10,
        style: { position: 'absolute', top: 190, left: 140, zIndex: 60 },
        animates: { auto: frames.slice(0, 94) },
        autoPlay: true,
        play: 'auto',
        id: 'standby2',
    },
    // hello2: { // 打招呼level2
    //     src: charac_suit02_wave_layer_ss, width: 200, height: 350, row: 6, col: 10,
    //     style: { position: 'absolute', top: 190, left: 140, zIndex: 60 },
    //     animates: { auto: frames.slice(0, 57) },
    //     autoPlay: false,
    //     play: 'auto',
    //     id: 'hello2',
    // },
    standby3: { // 待机level3
        src: charac_suit03_idle_layer_ss, width: 200, height: 350, row: 10, col: 10,
        style: { position: 'absolute', top: 190, left: 140, zIndex: 60 },
        animates: { auto: frames.slice(0, 94) },
        autoPlay: true,
        play: 'auto',
        id: 'standby3',
    },
    // hello3: { // 打招呼level1
    //     src: charac_suit03_wave_layer_ss, width: 200, height: 350, row: 6, col: 10,
    //     style: { position: 'absolute', top: 190, left: 140, zIndex: 60 },
    //     animates: { auto: frames.slice(0, 57) },
    //     autoPlay: false,
    //     play: 'auto',
    //     id: 'hello3',
    // },
}

const CharacterEntity = () => {
    const [level, setLevel] = useState(1);
    const [isHello, setIsHello] = useState(false);
    const [visible, setVisible] = useState(true);
    const userInfo = useUserInfo();
    const [loaded, setLoaded] = useState(false)
    const [loop, setLoop] = useState(false);
    const [onlyFirstFrame, setOnlyFirstFrame] = useState(true);

    const onLoad = () => {
        loadedCount++;
        if (loadedCount >= 3) {
            picListener.down('character');
        }
    }

    const getStatus = async () => {
        const data = await taskStatusApi();
        if (data && data.over_time) {
            setVisible(false);
        }
    }

    // const helloEnd = () => {
    //     if (playEndTimer) {
    //         clearTimeout(playEndTimer)
    //     }
    //     playEndTimer = setTimeout(() => {
    //         setIsHello(false)
    //         playEndTimer = null
    //     }, 200)
    // }

    const startHelloTick = () => {
        if (!helloTimer) {
            helloTimer = setInterval(() => {
                setIsHello(true);
            }, 20000);
        }
    }

    const playAnimate = () => {
        if (playTimer) return;
        playTimer = setTimeout(() => {
            setOnlyFirstFrame(false);
        }, 200)
    }

    const playEnd = () => {
        clearTimeout(playTimer);
        setOnlyFirstFrame(true);
    }

    useEffect(() => {
        // startHelloTick();
        userInfo.request();
        getStatus();

        picListener.on('dispatch_task', 'character', () => {
            setTimeout(() => {
                setVisible(false);
            }, 2000)
        })

        picListener.on('update_userinfo', 'character', (data) => {
            if (data.info.image && data.info.image !== level) {
                picListener.trigger('update_level');
            }
            setLevel(data.info.image ? data.info.image + 1 : 1);
        })
    }, [])

    useEffect(() => {
        if (!userInfo.error && userInfo.data) {
            setLevel(userInfo.data.info.image ? userInfo.data.info.image + 1 : 1);
        }
    }, [userInfo.data, userInfo.error])

    return <>
        {/* level1 */}
        {EntityV2({
            onClick: playAnimate,
            layoutStyle: pics.standby1?.style || {},
            width: pics.standby1?.width,
            height: pics.standby1?.height,
            animates: pics.standby1?.animates,
            autoPlay: pics.standby1?.autoPlay,
            show: level === 1 && !isHello && visible,
            loop,
            onlyFirstFrame,
            row: pics.standby1?.row,
            col: pics.standby1?.col,
            play: pics.standby1?.play,
            status: 1,
            id: pics.standby1?.id,
            key: pics.standby1?.id,
            url: pics.standby1?.src,
            onLoad: onLoad,
            playEnd: playEnd,
        })}
        {/* {EntityV2({
            layoutStyle: pics.hello1?.style || {},
            width: pics.hello1?.width,
            height: pics.hello1?.height,
            animates: pics.hello1?.animates,
            autoPlay: pics.hello1?.autoPlay,
            show: level === 1 && isHello && visible,
            loop,
            onlyFirstFrame,
            row: pics.hello1?.row,
            col: pics.hello1?.col,
            play: pics.hello1?.play,
            status: 1,
            id: pics.hello1?.id,
            key: pics.hello1?.id,
            url: pics.hello1?.src,
            onLoad: onLoad,
            playEnd: playEnd,
        })} */}

        {/* level2 */}
        {EntityV2({
            onClick: playAnimate,
            layoutStyle: pics.standby2?.style || {},
            width: pics.standby2?.width,
            height: pics.standby2?.height,
            animates: pics.standby2?.animates,
            autoPlay: pics.standby2?.autoPlay,
            show: level === 2 && !isHello && visible,
            loop,
            onlyFirstFrame,
            row: pics.standby2?.row,
            col: pics.standby2?.col,
            play: pics.standby2?.play,
            status: 1,
            id: pics.standby2?.id,
            key: pics.standby2?.id,
            url: pics.standby2?.src,
            onLoad: onLoad,
            playEnd: playEnd,
        })}
        {/* {EntityV2({
            layoutStyle: pics.hello2?.style || {},
            width: pics.hello2?.width,
            height: pics.hello2?.height,
            animates: pics.hello2?.animates,
            autoPlay: pics.hello2?.autoPlay,
            show: level === 2 && isHello && visible,
            loop,
            onlyFirstFrame,
            row: pics.hello2?.row,
            col: pics.hello2?.col,
            play: pics.hello2?.play,
            status: 1,
            id: pics.hello2?.id,
            key: pics.hello2?.id,
            url: pics.hello2?.src,
            onLoad: onLoad,
            playEnd: playEnd,
        })} */}

        {/* level3 */}
        {EntityV2({
            onClick: playAnimate,
            layoutStyle: pics.standby3?.style || {},
            width: pics.standby3?.width,
            height: pics.standby3?.height,
            animates: pics.standby3?.animates,
            autoPlay: pics.standby3?.autoPlay,
            show: level === 3 && !isHello && visible,
            loop,
            onlyFirstFrame,
            row: pics.standby3?.row,
            col: pics.standby3?.col,
            play: pics.standby3?.play,
            status: 1,
            id: pics.standby3?.id,
            key: pics.standby3?.id,
            url: pics.standby3?.src,
            onLoad: onLoad,
            playEnd: playEnd,
        })}
        {/* {EntityV2({
            layoutStyle: pics.hello3?.style || {},
            width: pics.hello3?.width,
            height: pics.hello3?.height,
            animates: pics.hello3?.animates,
            autoPlay: pics.hello3?.autoPlay,
            show: level === 3 && isHello && visible,
            loop,
            onlyFirstFrame,
            row: pics.hello3?.row,
            col: pics.hello3?.col,
            play: pics.hello3?.play,
            status: 1,
            id: pics.hello3?.id,
            key: pics.hello3?.id,
            url: pics.hello3?.src,
            onLoad: onLoad,
            playEnd: playEnd,
        })} */}
    </>
}

export default memo(CharacterEntity)