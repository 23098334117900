import { useState, useEffect, useCallback, memo } from 'react';
import s from './style.module.css';
import picListener from '../utils/picListener';

import room_out from "../../assets/room_out.png";
import room_wait from "../../assets/room_wait.png";

const Scene = ({ children }) => {
    const [loaded, setLoaded] = useState(false)
    const [outing, setOuting] = useState(false);
    useEffect(() => {
        picListener.listen('scene', () => {
            setLoaded(true);
        })
        picListener.on('dispatch_task_api', 'scene', () => {
            setOuting(true);
        })
        picListener.on('dispatch_task', 'scene', () => {
            setOuting(true);
        })
    }, [])

    return (
        <div className={`${s.scene} ${loaded ? s.loaded : s.loading}`}>
            <img src={outing ? room_out : room_wait} className={s.room} />
            {children}
        </div>
    )
}

export default memo(Scene);