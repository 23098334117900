import { useState, useEffect, memo, useCallback } from "react";
import { Popup } from 'antd-mobile'
import s from './style.module.css';

import taskbg3 from '../../assets/ui/taskbg1.png';
import taskbg1 from '../../assets/ui/taskbg2.png';
import taskbg2 from '../../assets/ui/taskbg3.png';
import gold from '../../assets/ui/gold.png';
import { completionPaddingByIos } from '../../utils';

let timer;
let surplus;

const Outing = ({ visible, onClose, initTime, taskType }) => {
    const [hours, setHours] = useState('00')
    const [minutes, setMinutes] = useState('00')
    const [seconds, setSeconds] = useState('00')
    function secondsToTime(time) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return [
            hours,
            minutes,
            seconds,
        ];
    }

    const startTick = () => {
        if (!timer) {
            timer = setInterval(() => {
                const [h, m, s] = secondsToTime(surplus || initTime);
                setHours(`${h}`.length === 1 ? `0${h}` : h);
                setMinutes(`${m}`.length === 1 ? `0${m}` : m);
                setSeconds(`${s}`.length === 1 ? `0${s}` : s);
                surplus = surplus ? surplus - 1 : initTime - 1;
            }, 1000)
        }
    }

    useEffect(() => {
        startTick()
    }, [initTime], () => {
        clearInterval(timer)
    })

    return (
        <Popup
            visible={visible}
            bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                ...completionPaddingByIos(),
            }}
            onMaskClick={() => {
                onClose()
            }}
        >
            <div>
                <div className={s.countdownLayout}>
                    <div className={s.title}>金铃宝宝出游中</div>
                    <div className={s.countdown}>
                        <div className={s.timer}>{hours}</div>
                        <div className={s.point}>:</div>
                        <div className={s.timer}>{minutes}</div>
                        <div className={s.point}>:</div>
                        <div className={s.timer}>{seconds}</div>
                        <div className={s.backText}>后回家</div>
                    </div>
                </div>
                <div className={s.taskList}>
                    {taskType === 6 && <div className={`${s.taskItem} ${s.task1}`}>
                        <img src={taskbg1} className={s.bg} />
                        <div className={s.infoPanel}>
                            <div className={s.taskName}>太空物流</div>
                            <div className={s.taskInfo}>
                                <div className={s.time}>时长: </div>
                                <div>10小时</div>
                            </div>
                            <div className={s.gift}>
                                <div>奖励: </div>
                                <div>20个<img className={s.icon} src={gold} /></div>
                            </div>
                        </div>
                    </div>}
                    {taskType === 7 && <div className={`${s.taskItem} ${s.task2}`}>
                        <img src={taskbg2} className={s.bg} />
                        <div className={s.infoPanel}>
                            <div className={s.taskName}>样本采集</div>
                            <div className={s.taskInfo}>
                                <div className={s.time}>时长: </div>
                                <div>8小时</div>
                            </div>
                            <div className={s.gift}>
                                <div>奖励: </div>
                                <div>10个<img className={s.icon} src={gold} /></div>
                            </div>
                        </div>
                    </div>}
                    {taskType === 8 && <div className={`${s.taskItem} ${s.task3}`}>
                        <img src={taskbg3} className={s.bg} />
                        <div className={s.infoPanel}>
                            <div className={s.taskName}>飞船维修</div>
                            <div className={s.taskInfo}>
                                <div className={s.time}>时长: </div>
                                <div>6小时</div>
                            </div>
                            <div className={s.gift}>
                                <div>奖励: </div>
                                <div>5个<img className={s.icon} src={gold} /></div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className={s.bottomBtnPanel}>
                    <div onClick={onClose} className={s.bottomBtn}>确定</div>
                </div>
            </div>
        </Popup>
    )
}

export default memo(Outing);