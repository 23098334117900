import { useState, useEffect, useCallback, memo } from 'react';
import s from './style.module.css';
import UserInfo from '../userinfo';
import Signin from '../signin';
import TaskCenter from '../taskcenter';
import OutTask from '../outtask';
import Outing from '../outing';
import Code from '../code';

import gold from '../../assets/ui/gold.png';
import popup from '../../assets/ui/popup.png';
import getcode from '../../assets/ui/getcode.png';
import taskIcon from '../../assets/ui/task.png';

import useUserInfo from '../../hooks/useUserInfo';
import getGoldApi from '../../api/getgold';
import showGoldAPi from '../../api/showgold';
import taskListApi from '../../api/tasklist';
import taskStatusApi from '../../api/taskinfo';
import getTaskApi from '../../api/gettask';

import picListener from '../utils/picListener';

const UI = () => {
    const [visibleOut, setVisibleOut] = useState(false);
    const [outing, setOuting] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [codeVisible, setCodeVisible] = useState(false);
    const [task, setTask] = useState(0);
    const [initTime, setInitTime] = useState(0)
    const [showGold, setShowGold] = useState(false);
    const [userInfo, setUserInfo] = useState({ info: {} });

    const userInfoApi = useUserInfo();

    const getTask = async (id) => {
        const data = await getTaskApi(id);
        setOuting(true);
        setInitTime(data.over_time)
        picListener.trigger('dispatch_task')
    }

    const getTaskList = async () => {
        const data = await taskListApi(1)
        setTasks(data)
    }

    const taskStatus = async () => {
        const data = await taskStatusApi();
        if (data && data.over_time) {
            setOuting(true)
            setInitTime(data.over_time)
            setTask(data.task_id);
            picListener.trigger('dispatch_task_api');
        }
    }

    const openOutTask = () => {
        setVisibleOut(true)
    }
    const getGold = async () => {
        await getGoldApi();
        setShowGold(false);
        userInfoApi.request();
    }

    const getShowGold = async () => {
        const data = await showGoldAPi();
        if (data === 1) {
            setShowGold(true)
        }
    }

    useEffect(() => {
        userInfoApi.request();
        getShowGold();
        getTaskList();
        taskStatus();
    }, [])

    useEffect(() => {
        if (!userInfoApi.error && userInfoApi.data) {
            setUserInfo(userInfoApi.data)
            picListener.trigger('update_userinfo', userInfoApi.data)
        }
    }, [userInfoApi.error, userInfoApi.data])

    return (
        <div className={s.uiLayout}>
            <div className={s.controls}>
                <div onClick={openOutTask} className={`${s.openTask} ${s.controlItem}`}>
                    <img src={taskIcon} className={s.controlIcon} />
                    <div className={s.controlTitle}>外出任务</div>
                </div>
                <div onClick={() => setCodeVisible(true)} className={`${s.openCode} ${s.controlItem}`}>
                    <img src={getcode} className={s.controlIcon} />
                    <div className={s.controlTitle}>获得邀请码</div>
                </div>
                {showGold && <div onClick={getGold} className={`${s.getGold} ${s.controlItem}`}>
                    <img src={gold} className={s.controlIcon} />
                    <div className={s.controlTitle}>获得金豆</div>
                </div>}
            </div>
            <UserInfo userInfo={userInfo} />
            <Signin onOk={() => userInfoApi.request()} />
            <TaskCenter />
            {!outing && <OutTask
                tasks={tasks}
                onClose={() => setVisibleOut(false)}
                visible={visibleOut}
                onOk={getTask}
            />}
            {outing && <Outing
                onClose={() => setVisibleOut(false)}
                visible={visibleOut}
                taskType={task}
                initTime={initTime}
            />}
            <Code
                code={userInfo.invite_code}
                onClose={() => setCodeVisible(false)}
                visible={codeVisible}
            />
        </div>
    )
}

export default memo(UI);