import { useState, useEffect, useCallback, memo } from 'react';
import { Toast } from 'antd-mobile';
import s from './style.module.css';
import gift from '../../assets/ui/gift.png';
import gold from '../../assets/ui/gold.png';
import Checkmark from '../../assets/ui/Checkmark.png';

import signinApi from '../../api/signin';
import signinListApi from '../../api/signinlist';
import useUserInfo from '../../hooks/useUserInfo';

const Signin = ({ onOk }) => {
    const [level, setLevel] = useState(1);
    const [signined, setSignined] = useState([0, 0, 0, 0, 0, 0, 0])
    const [signinStatus, setSigninStatus] = useState({});
    const useInfo = useUserInfo();

    const signin = async () => {
        await signinApi();
        Toast.show({
            icon: 'success',
            content: '签到成功!',
        })
        getSigninList();
        onOk();
    }

    const getSigninList = async () => {
        const data = await signinListApi();
        if (data) {
            const newSignined = signined.map((item, index) => {
                if (index + 1 <= data.cycle) {
                    return 1;
                }
                return 0;
            })
            setSignined(newSignined)
            setSigninStatus(data);
        }
    }

    useEffect(() => {
        getSigninList()
        useInfo.request();
    }, [])

    useEffect(() => {
        if (!useInfo.error && useInfo.data) {
            setLevel(useInfo.data.info.image ? useInfo.data.info.image + 1 : 1);

        }
    }, [useInfo.error, useInfo.data])


    return (
        <>
            <div className={s.signin}>
                <div className={s.signinTitle}>
                    <div className={s.mainTitle}>连续签到</div>
                    <div className={s.explain}>解锁新形象</div>
                    <div className={s.siginBtn} onClick={signin}>每日签到</div>
                </div>
                <div className={s.signinList}>
                    {signined.map((item, index) => (
                        <div key={index} className={s.signinItem}>
                            <div>
                                <img src={item ? Checkmark : (index === 6 && level < 3) ? gift : gold} className={s.signinIcon} />
                                {index !== 6 ? !item ? <i className={s.signinAddNum}>+1</i> : null : null}
                            </div>
                            第{index + 1}天
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default memo(Signin);