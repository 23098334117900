import { memo, useEffect, useState } from 'react';
import EntityV2 from '../components/entityv2';
import s from './style.module.css'

import transition_layer_ss from '../assets/transition_layer_ss.webp'
import picListener from '../components/utils/picListener';

const frames = [
    // [x, y]
    [0, 0],
    [-390, 0],
    [-780, 0],
    [-1170, 0],
    [-1560, 0],
    [-1950, 0],
    [-2340, 0],
    [-2730, 0],
    [-3120, 0],
    [-3510, 0],

    [0, -844],
    [-390, -844],
    [-780, -844],
    [-1170, -844],
    [-1560, -844],
    [-1950, -844],
    [-2340, -844],
    [-2730, -844],
    [-3120, -844],
    [-3510, -844],

    [0, -1688],
    [-390, -1688],
    [-780, -1688],
    [-1170, -1688],
    [-1560, -1688],
    [-1950, -1688],
];

let timer = null;

const animete = { // 屏幕1
    src: transition_layer_ss, width: 390, height: 844, row: 3, col: 10,
    style: { position: 'absolute', top: 0, left: 0, zIndex: 1001 },
    animates: { close: frames.slice(0, 13), open: frames.slice(13) },
    autoPlay: true,
    id: 'loading',
};

const Loading = () => {
    const [stop, setStop] = useState(true);
    const [play, setPlay] = useState('open');

    useEffect(() => {
        picListener.on('dispatch_task', 'loading', () => {
            setStop(false)
            setPlay('close')
        })

        picListener.on('update_level', 'loading', (data) => {
            setStop(false);
            setPlay('close');
        })
    }, [])

    useEffect(() => {
        if (play === 'close' && !stop && !timer) {
            timer = setTimeout(() => {
                setPlay('open')
                setTimeout(() => {
                    setStop(true);
                    timer = null;
                }, 3000)
            }, 3000)
        }
    }, [play, stop])

    if (stop) return null;

    return (
        <>
            {EntityV2({
                layoutStyle: animete?.style || {},
                width: animete?.width,
                height: animete?.height,
                animates: animete?.animates,
                autoPlay: animete?.autoPlay,
                show: !stop,
                loop: false,
                row: animete?.row,
                col: animete?.col,
                play,
                hangup: true,
                status: 1,
                id: animete?.id,
                key: animete?.id,
                url: animete?.src,
                // onLoad: onLoad,
                // playEnd: () => helloEnd(),
            })}
        </>
        // <div className={`${s.loading} ${close ? s.close : ''}`}>
        //     <img src={loadingPic} className={s.loadingJpg} />
        //     <img src={loadingGif} className={s.loadingGif} />
        // </div>
    )
}

export default memo(Loading)