import { useState, useEffect, useCallback, memo, useRef } from 'react';
import s from './style.module.css';
const ImageCache = ({ src, alt, style, onLoad }) => {
    const [imageSrc, setImageSrc] = useState(null);
    useEffect(() => {
        // 预加载图片并存储在缓存中
        const img = new Image();
        img.onload = () => setImageSrc(src);
        img.src = src;
    }, [src]);
    return <img src={imageSrc || src} alt={alt} style={style} onLoad={onLoad} />;
};

const EntityFnc = (props) => {
    let frame = null;

    const Entity = ({
        id,
        show,
        url,
        width,
        height,
        onLoad,
        autoStart,
        play,
        animates,
        row,
        col,
        blank,
        layoutStyle,
        loop = false,
        playEnd,
        status,
    }) => {
        const ref = useRef(null);
        const [top, setTop] = useState(0);
        const [left, setLeft] = useState(0);
        const [times, setTimes] = useState([]);
        const [fream, setFrame] = useState(null);

        const animate = (animateData, isLoop, frameCount) => {
            if (show) {
                if (frameCount === 0) {
                    const [start, end] = animateData;

                    let currentRow = 0;
                    let startFrame = frame === null ? start : frame;
                    const _frame = frame === null ? start : frame;
                    for (let i = 0; i < frame; i) {
                        if ((startFrame - col) < 0) {
                            break;
                        }
                        currentRow += 1;
                        startFrame = startFrame - col;
                    }
                    const currentCol = _frame % col;
                    const newFrame = _frame + 1;
                    if (newFrame >= end) {
                        if (isLoop) {
                            frame = start;
                        } else {
                            frame = end;
                        }
                    } else {
                        frame = newFrame;
                    }
                    const newTop = -(currentRow * height);
                    const newLeft = -(currentCol * width);
                    console.log(newTop)

                    if (ref.current) {
                        if (id === 'standby1') {
                            console.log(newTop)
                        }
                        ref.current.style.top = `${newTop}px`;
                        ref.current.style.left = `${newLeft}px`;
                    }
                    if (!isLoop && newFrame > end) {
                        times.forEach(t => {
                            cancelAnimationFrame(t)
                        })
                        frame = null;
                        setTimes([])
                        if (playEnd) playEnd();
                        return;
                    };
                }
                times.push(requestAnimationFrame(animate.bind(this, animateData, isLoop, frameCount === 5 ? 0 : frameCount + 1)))
            }
        }

        useEffect(() => {
            if (autoStart && play && animates && animates[play]) {
                times.push(requestAnimationFrame(animate.bind(this, animates[play], loop, 0)));
            }
        }, [])

        useEffect(() => {
            if (animates && animates[play] && times && show) {
                if (times.length) {
                    times.forEach(t => cancelAnimationFrame(t))
                    setTimes([]);
                }
                times.push(requestAnimationFrame(animate.bind(this, animates[play], loop, 0)));
            }
        }, [play, show])

        return (
            <div style={{
                width,
                height,
                overflow: 'hidden',
                position: 'relative',
                ...layoutStyle,
                opacity: show ? 1 : 0,
            }}>
                <img
                    onContextMenu={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onTouchStart={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    style={{
                        width: width * col,
                        height: height * row,
                        position: 'absolute',
                    }}
                    ref={ref}
                    src={url}
                    onLoad={onLoad}
                />
            </div>
        )
    }
    return <Entity {...props} key={props.key} />;
}

export default EntityFnc